/* You can add global styles to this file, and also import other style files */
@use "base-styles/base-styles";
@use 'base-styles/abstracts/spacing' as s;
@use "base-styles/themes/edf" as theme;

.edf {
  @include theme.edf();
}
.grecaptcha-badge {
  visibility: hidden;
}
